




























import Vue from 'vue';
import { confirmUpdateVersions } from '@/api/project';
export default Vue.extend({
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    cancel() {
      this.confirmUpdateVersions();
      this.$emit('update:visible', false);
    },
    confirmUpdateVersions() {
      confirmUpdateVersions().then((res) => {
        console.log('关闭');
      });
    },
  },
});
